.cal-event-color-picker--container {
  margin-right: 16px;
}

.cal-event-color-picker--color {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin: 8px;
}

.cal-event-color-picker--color-square.small {
  margin: 4px;
}

.cal-event-color-picker--color-square {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.cal-event-tag-picker--options {
  width: 200px;
}

.cal-event-color-picker--options {
  display: flex;
  flex-wrap: wrap;
  width: 64px;
}

.cal-event-color-picker--container .cal-event-color-picker--color {
  margin-right: 0;
}


.cal-event-color-picker--option.selected {
  cursor: initial;
}

.cal-event-color-picker--option {
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.cal-event-color-picker--tick {
  position: absolute;
  top: 12px;
  left: 12px;
  color: white;
  z-index: 1;
}

.cal-event-color-picker--option:not(.selected):hover {
  background-color: var(--color-neutral-5);
}

.cal-event-color--tag {
  display: flex;
}

.cal-event-color--edit:hover {
  color: var(--color-neutral-9);
}

.cal-event-color--edit {
  color: var(--color-neutral-7);
  margin-left: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 4px 8px;
}


.cal-tag-popover--option.inline {
  justify-content: unset;
}

.cal-tag-popover--option:not(.selected):hover {
  background-color: var(--color-neutral-5);
}

.cal-tag-popover--option {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cal-tag-popover-color-options--container {
  display: flex;
  max-width: 200px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.cal-tag-popover-color--option:hover {
  background: var(--color-neutral-5);
}

.cal-tag-popover-color--option {
  position: relative;
  cursor: pointer;
  background: white;
  transition: background 0.3s ease;
}

.cal-event-color--create-new:hover {
  color: var(--color-purple-6);
  background: var(--color-neutral-5);
}

.cal-event-color--create-new {
  color: var(--color-purple-5);
  font-size: 12px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, background 0.3s ease;
  cursor: pointer;
  margin-bottom: 4px;
  padding-right: 4px;
}

.cal-event-color-create--icon {
  margin: 4px 0px 8px 2px;
  /* border: 1px solid var(--color-purple-5); */
  padding: 6px 0px 2px 6px;
}

.cal-tag-popover-edit--container {
  padding: 12px;
}

.cal-tag-popover-edit--name {
  max-width: 200px;
  display: block;
  margin: 12px 0;
}

.cal-tag-popover-color-options--label {
  color: var(--color-neutral-10);
  margin-bottom: 4px;
  font-size: 12px;
}