.gdrive-sender-ctx--loading > * {
  margin-left: 2px;
}

.gdrive-sender-ctx--loading {
  display: inline-flex;
  color: var(--color-neutral-10);
  align-items: baseline;
}

.document-sender-context--loading {
  display: flex;
  align-items: baseline;
  color: var(--color-neutral-10);
}