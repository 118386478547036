.connect-another-account--card {
 width: 458px
}

.connect-another-account--buttons{
  display: flex;
}

.classify-btn.connect-another-account--slack-button {
  display: inline-block;
  padding-left: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: Roboto,arial,sans-serif;
  border-radius: 0;
  position: relative;
  height: 50px;
  margin-left: 16px;
  background-color: #611f69;
  width: 232px;
  text-align: start;
}

.classify-btn.connect-another-account--slack-button:hover {
  background-color: #4a154b;
}

.slack-button--icon {
  width: 22px;
  left: 13px;
  position: absolute;
  top: 13px;
}

.connect-another-account--accounts-header {
  font-size: 18px;
  margin-top: 54px;
}

.connected-account {
  display: flex;
  height: 40px;
  padding: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.connected-account--icon {
 width: 40px;
 margin-right: 16px;
}

.connect-another-account--footer .connect-another-account--done-button {
  border: 1px solid var(--color-purple-5);
  color: var(--color-purple-5);
}

.connect-another-account--card .connect-another-account--footer .connect-another-account--done-button:hover {
  border: 1px solid var(--color-purple-5);
  background-color: var(--color-purple-1);
}

.connect-another-account--footer {
  display: flex;
  justify-content: right;
  margin-top: 16px;
}

.connect-account-account--loader {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-weight: 500;
  color: var(--color-neutral-11);
}

.connect-another-account--loading-dots {
  margin-left: 4px;
}

.connect-another-account--alert {
  margin-bottom: 16px;
}
