.message-context-message--container.highlight {
  background: #f4f4f4;
}

.message-context-message--container {
  margin-bottom: 8px;
  padding: 8px 16px;
}

.message-context-sender--container {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--color-neutral-9);
}

.message-context--content {
  margin-top: 4px;
  font-size: 12px;
}

.message-context--sent-at {
  font-size: 12px;
  color: var(--color-neutral-9);
}

.message-context--file:hover {
  background: #e9e9e9;
}

.message-context--file {
  text-align: center;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 8px;
  margin-top: 6px;
  width: 40%;
  background: white;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.5s ease;
}

.document-contexts--container {
  margin-bottom: 100px;
}

.document-context--options {
  display: flex;
  margin-top: 16px;
}

.document-context--option.active {
  border-bottom-color: var(--color-purple-5);
  color: var(--color-purple-5);
}

.document-context--option:not(.active):hover {
  border-bottom-color: var(--color-purple-3);
}

.document-context--option {
  width: 80px;
  text-align: center;
  padding: 5px 0px 12px;
  margin-right: 8px;
  font-size: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--color-blue-2);
  border-bottom: 2px solid transparent;
}

.document-context--title {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-blue-3);
}

.document-context-options--container {
  padding: 16px;
}

.message-context--icon {
  min-width: 24px;
  width: 24px;
  margin: 0 8px;
}

.message-context-message--header {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s ease;
  cursor: pointer;
  background: white;
  border-radius: 5px;
}

.message-context-message-header--container {
  display: flex;
  width: calc(100% - 32px);
}


.message-context-message--header.open {
  border-bottom: 1px solid var(--color-neutral-8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.message-context-subject--container {
  display: inline-block;
  flex-shrink: 1;
  min-width: 0px;
}

.message-context-message--header:hover {
  background: #f3f3f6;
}

.message-context--avatar {
  margin-right: 8px;
}

.message-context--row {
  display: flex;
  margin-bottom: 8px;
  padding: 0 16px;
}

.activity-context-content--actor {
  font-weight: 600;
}

.message-context-row--content {
  flex-grow: 1;
  max-width: 90%;
}

.message-context-message-group--container {
  padding: 8px 0;
  overflow-x: auto;
}

.message-context-message-group--container.hightlight {
  background: var(--color-purple-1);
}

.message-context-message-group--container:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.people-context-section--container {
  padding: 16px 16px 0;
}

.people-context-section--container ul {
  list-style: none;
}

.people-context-section--title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-blue-2);
}

.message-context-ext-link--container {
  padding: 8px;
  text-align: center;
  border: 1px solid blue;
  border-radius: 5px;
}

.message-context-subject-link--container {
  color: var(--color-neutral-12);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.message-context-subject--recipients {
  font-size: 10px;
  color: var(--color-neutral-11);
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.message-context-content--container {
  border: 1px solid var(--color-neutral-8);
  border-radius: 5px;
  margin: 8px 16px;
}

.message-context-expanded--icon.open {
  transform: rotate(0deg);
}

.message-context-expanded--icon {
  transform: rotate(-180deg);
  padding: 8px 4px;
  transition: all 0.3s ease;
}

.people-context-name--link:hover {
  color: darkblue;
}

.people-context-name--link {
  color: blue;
  transition: color 0.3s ease;
  cursor: pointer;
}

.message-context-empty--text {
  margin-left: 16px;
  font-size: 14px;
  color: var(--color-neutral-9);
}

.activity-context-content--container {
  margin: 16px 32px;
  display: flex;
  align-items: center;
  color: var(--color-neutral-12);
}

.activity-context--icon {
  min-width: 24px;
  max-width: 24px;
  margin-right: 12px;
}

.activity-context-content--subtitle {
  font-size: 10px;
  color: var(--color-neutral-11);
}

.activity-context-content--container.immutable.sidebar {
  margin: 8px 16px;
}

.activity-context-content--container.immutable {
  align-items: center;
  display: flex;
  margin: 8px 0;
  padding: 8px 12px;
  background-color: var(--color-neutral-4);
  border-radius: 5px;
  color: var(--color-neutral-12);
}

.activity-context-content-title--container {
  max-width: 22vw;
}

.activity-context-content--title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.message-context-content--line {
  margin-top: 4px;
  min-height: 1px;
}

.activity-context-content--loading {
  display: inline-flex;
  color: var(--color-neutral-10);
  align-items: baseline;
  font-size: 14px;
}

.message-context--quote {
  font-weight: 500;
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  color: var(--color-blue-2-4);
  border-left: 4px solid var(--color-blue-2-4);
}

.message-context-message--collapse:hover {
  color: var(--color-purple-7);
  background: var(--color-neutral-4);
}

.message-context-message--collapse {
  color: var(--color-purple-6);
  background: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}


.activity-messages-links--container {
  display: flex;
  overflow: auto;
  max-width: 100%;
  padding: 0 16px 16px;
}

.activity-context-content--link:hover {
  color: var(--color-blue-2-4);
}

.activity-context-content--link {
  text-decoration: underline;
  color: var(--color-neutral-11);
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s ease;
}

.activty-context-content-subtitle--loading {
  margin-right: 2px;
}