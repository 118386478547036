
.calendar-event-details--section > .action-item-children--container:not(:last-child),
.calendar-event-details--section > .action-item--container:not(:last-child):not(.has-child) {
  border-bottom: 1px solid var(--color-neutral-7);
}

.action-item--container {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

input.action-item-title--input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

input.action-item-title--input.done {
  text-decoration: line-through;
}

input.action-item-title--input:disabled {
  background: transparent;
  color: var(--color-neutral-10);
}

input.action-item-title--input {
  border: none;
  font-size: 12px;
  flex-grow: 1;
}

label.action-item--status {
  margin: 0;
}

.action-item--created {
  color: var(--color-neutral-10);
  font-size: 12px;
}

.calendar-event-details--empty {
  margin: 64px auto 0;
  text-align: center;
  color: var(--color-neutral-9);
  font-size: 12px;
}

.action-item-children--container {
  padding-left: 16px;
}