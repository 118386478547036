.classify--table {
  border-collapse: collapse;
  width: 100%;
}

.classify--table .classify--thead .classify--tr {
  background: var(--color-neutral-4);
  border-bottom: 1px solid var(--color-neutral-7);
}

.classify--table .classify--th {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  color: var(--color-neutral-10);
  padding: 16px 24px;
  text-align: left;
}

.classify--table .classify--tbody .classify--tr {
  cursor: pointer;
  transition: background 0.3s ease;
}

.classify--table .classify--tbody .classify--tr.selected,
.classify--table .classify--tbody .classify--tr:hover {
  background: var(--color-neutral-4);
}

.classify--table .classify--tbody .classify--td {
  padding: 16px 24px;
  border-bottom: 1px solid #E6E8F0;
}

.contact-list--loading {
  text-transform: uppercase;
  font-size: 12px;
  padding: 24px;
  color: var(--color-neutral-10);
}

.contacts-list--container {
  margin: 40px 80px;
}

.contact-list--header {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-neutral-11);
}

.contact-list--actions {
  margin-bottom: 24px;
}

.contact-list-actions--button {
  margin-right: 16px;
}

.contact-list-row-handle--icon {
  width: 24px;
  margin-right: 8px;
}

.contact-list--loading-content {
  display: flex;
  align-items: baseline;
}
