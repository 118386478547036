
.search-content--container {
  flex-grow: 1;
  width: calc(70vw - 230px);
  max-width: 70vw;
}

.search--container {
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
}