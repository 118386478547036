.signup-card-footer {
 text-align: center;
}

.signup-card-helper-text {
  font-size: 12px;
}

.signup-card-link {
  text-decoration: none;
  color: var(--color-purple-5);
  transition: all 0.3s ease;
}

.signup-card-link:hover {
  color: var(--color-purple-7);
}
