.search-bar--input {
  width: 100%;
  display: block;
  font-size: 16px;
  color: #4f4f4f;
  background: #F9F9F9;
}

.search-bar--container {
  margin: 20px 96px;
  display: flex;
  align-items: center;
  position: relative;
}

.search-bar--icon {
  padding: 0 0 0 12px;
  color: var(--color-blue-2);
}

@keyframes movealong {
  0% {
    width: 4%;
  }

  10% {
    width: 62%;
  }

  30% {
    width: 75%;
  }

  50% {
    width: 79%;
  }
  60% {
    width: 85%;
  }
  70% {
    width: 90%;
  }
  80% {
    width: 92%;
  }
  90% {
    width: 94%;
  }
  100% {
    width: 97%;
  }
}

.search-bar-loading--line.done {
  animation: none;
  width: 100%;
  opacity: 0;
}

.search-bar-loading--line {
  width: 1%;
  height: 2px;
  background: var(--color-orange-5);
  position: absolute;
  bottom: -1px;
  z-index: 2;
  animation: movealong 30s ease;
  transition: opacity 2s, width 10s;
}