.workstream-activity-note--container {
  padding-top: 12px;
  border: 1px solid var(--color-neutral-6);
  border-radius: 4px;
  background-color: var(--color-neutral-2);
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.workstream-activity-note--container:hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--color-purple-4);
  transition: all 0.3s ease;
}

.workstream-activity-note-content--container {
  display: flex;
}

.workstream-activity-note-icon--container {
  display: flex;
  padding-top: 12px;
  margin: 0 16px;
  color: var(--color-purple-5);
}

.workstream-activity-note-text--container {
  max-width: calc(100% - 64px);;
}

.workstream-activity-note-header--container {
  overflow: hidden;
}

.workstream-activity-note--header {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.workstream-activity-note--content {
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  padding-top: 8px;
  color: var(--color-neutral-11);
}

.workstream-activity-note--footer {
  font-size: 10px;
  display: flex;
  padding-right: 8px;
  padding-bottom: 8px;
  justify-content: flex-end;
  color: var(--color-neutral-10);
}

.workstream-activity-note-footer--empty {
  padding-bottom: 18px;
}