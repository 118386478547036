.note-editor--button.active {
  color: white;
  background: var(--color-neutral-7);
}

.note-editor--button:not(.active):hover {
  background: var(--color-neutral-5);
}

.note-editor--button {
  display: inline-flex;
  align-items: center;
  padding: 6px;
  color: var(--color-neutral-11);
  cursor: pointer;
  transition: background 0.3s ease;
}

.calendar-context-section-notes--format-container {
  border-top: 1px solid var(--color-neutral-7);
  padding: 0 8px;
}