.view-contact-sidebar--title {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--color-neutral-7);
}

.view-contact-sidebar--name {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-neutral-12);
  margin-left: 12px;
}

.view-contact-sidebar--avatar {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.view-contact-sidebar-handle--container {
  margin: 8px 8px 16px 16px;
  max-width: 200px;
}

.view-contact-sidebar-handle--icon {
  width: 20px;
  color: var(--color-blue-2-3);
  margin-right: 8px;
}

.view-contact-sidebar-handle--source {
  display: flex;
  border-bottom: 1px solid var(--color-neutral-6);
  color: var(--color-neutral-10);
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 14px;
  align-items: center;
}

.view-contact-sidebar-handle--name {
  color: var(--color-neutral-12);
  font-size: 14px;
}

.view-contact-sidebar-section--title {
  margin: 12px 16px;
  font-weight: 500;
  color: var(--color-neutral-12);
}

.view-contact-sidebar--handle {
  margin: 24px 0px 40px 0px;
}

.view-contact-sidebar--info:hover {
  border-color: var(--color-neutral-8);
  color: var(--color-neutral-8);
}

.view-contact-sidebar--info {
  color: var(--color-neutral-7);
  margin-left: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  padding: 5px 7px;
  border-radius: 4px;
}

.view-contact-sidebar-name--input {
  margin-left: 8px;
}

.view-contact-sidebar-documents--empty {
  font-size: 14px;
  margin: 8px 16px;
  color: var(--color-neutral-9);
  display: block;
}

.view-contact-sidebar-section--body {
  margin: 0 16px 12px 16px;
}

.contact-sidebar-tab--title {
  display: flex;
  align-items: baseline;
}

.contact-sidebar-tab-content--container.loading {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  color: var(--color-neutral-10);
}

.contact-sidebar-tab-content--container {
  padding: 0 16px;
}

/* contact modal */

.view-contact-sidebar--edit:hover {
  border-color: var(--color-neutral-8);
  color: var(--color-neutral-8);
}

.view-contact-sidebar--edit.save {
  color: var(--color-green-2-3);
}

.view-contact-sidebar--edit.cancel {
  color: var(--color-red-4);
}

.view-contact-sidebar--edit {
  color: var(--color-neutral-7);
  margin-left: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  padding: 5px 7px;
  border-radius: 4px;
}

.view-contact-sidebar--modal {
  display: flex;
  align-items: center;
}