.classify-btn {
  padding: 8px 16px;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
  font-weight: 600;
}

.classify-btn.danger {
  background: var(--color-red-3);
}

.classify-btn.danger:hover {
  background: var(--color-red-4);
}

.classify-btn.primary {
  background: var(--color-purple-5);
}

.classify-btn.subtle {
  background: white;
  color: var(--color-neutral-9);
}

.classify-btn.secondary {
  background: var(--color-blue-3);
}

.classify-btn.secondary:hover {
  background: var(--color-blue-4);
}


.classify-btn.large {
  padding: 12px 20px;
  font-size: 16px;
}

.classify-btn.primary:hover {
  background: var(--color-purple-6);
}

.classify-btn.subtle.disabled {
  background: white;
}

.classify-btn.subtle:hover {
  background: #F6F3FF;
}

.classify-btn.disabled:hover,
.classify-btn.disabled {
  background: var(--color-purple-3);
  cursor: not-allowed;
}

.classify-btn.subtle.disabled:hover,
.classify-btn.subtle.disabled {
  background: var(--color-neutral-4);
  cursor: not-allowed;
}


.loading-container--label {
  padding: 8px 16px;
  color: var(--color-blue-3);
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.classify-btn.subtle.disabled {
  color: var(--color-purple-3);
}

.classify-btn.subtle.active {
  background: var(--color-purple-1);
  color: var(--color-purple-5);
}