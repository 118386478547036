.workstream-note-footer--text:hover {
  text-decoration: underline;
}

.workstream-note-footer--text {
  display: flex;
  align-items: center;
}

.workstream-note-footer--icon {
  color: var(--color-neutral-10);
  margin-right: 4px;
}