.search-tabs--container {
  margin: 24px 92px 24px 96px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  border-bottom: 1px solid var(--color-neutral-7);
}

.search-tabs--name-icon {
  padding-bottom: 1px;
  padding-right: 4px;
}

.search-tabs--active-tab {
  padding-bottom: 8px;
  border-bottom: 4px solid var(--color-purple-5);
}

.search-facets--container {
  margin: 24px 0 24px 96px;
  display: flex;
  flex-wrap: wrap;
}

.search-facet--container {
  padding-right: 8px;
  min-width: 180px;
  font-size: 14px;
  margin-bottom: 8px;
}

.search-facet--title {
  padding: 8px;
}

.search-facet-options--container {
  font-size: 14px;
}

.search-facet-bucket--container:hover {
  background: #efefef;
}

.search-facet-bucket--container:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.search-facet-bucket--container {
  padding: 3px 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-facet-bucket--checkbox {
  width: 10px;
}

.facet-option-bucket--count {
  font-size: 8px;
  background: var(--color-blue-1);
  color: white;
  padding: 4px;
  margin-left: 8px;
  border-radius: 8px;
  min-width: 10px;
  display: inline-block;
  text-align: center;
  max-height: 10px;
}

.facet-option-bucket--label {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--color-blue-4);
}