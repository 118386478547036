.workstream-view-name-header--title {
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  padding: 8px 12px;
}

.workstream-view-name-header--edit {
  margin-left: 8px;
  cursor: pointer;
  color: var(--color-neutral-9);
}

.workstream-view-name--header {
  padding: 32px 32px 0;
  display: flex;
  align-items: center;
}

.workstream-timeline--empty-container {
  margin: 80px auto;
  padding: 80px 100px;
}

.workstream-timeline--empty-header {
  font-size: 15px;
  padding: 16px;
  color: var(--color-neutral-11);
  display: flex;
  justify-content: center;
}

.workstream-timeline--empty-subtitle {
  font-size: 11px;
  display: flex;
  justify-content: center;
}

/* workstream view tab links */

.workstream-view-tabs--icon {
  padding-right: 8px;
}

.workstream-view-tab--container {
  display: flex;
  border-bottom: 1px solid var(--color-neutral-7);
  padding: 16px 0px 0px 16px;
}

.workstream-view-tab--option {
  color: var(--color-neutral-10);
  margin-right: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  cursor: pointer;
  min-width: 48px;
  text-align: center;
  padding-top: 8px;
  padding-left: 16px;
  text-decoration: none;
  font-weight: 500;
}

.workstream-view-tab--option.active {
  font-weight: 600;
  color: var(--color-purple-5);
}

.workstream-view-tab--underline {
  border-top: 2px solid transparent;
  position: relative;
  bottom: -4px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: border-top-color 0.3s ease;
  margin-top: 4px;
}

.workstream-view-tab--option:hover>.workstream-view-tab--underline {
  border-top-color: var(--color-neutral-7);
}

.workstream-view-tab--option.active>.workstream-view-tab--underline {
  border-top-color: var(--color-purple-5);
}

/* tagged activity events + UI split screen */

.workstreams-tagged-activity-content-split--container {
  display: flex;
  height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
}

.workstreams-tagged-activity-events--container {
  padding: 24px 0px 40px 32px;
  flex-grow: 1;
  overflow: auto;
  padding-right: 16px;
  border-right: 1px solid var(--color-neutral-7);
  max-width: calc(100% - 30vw - 48px);
}

.workstreams-tagged-activity-related-content--container {
  min-width: 30vw;
  width: 30vw;
  flex-shrink: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.workstreams-activity-preview-empty--container,
.workstreams-tagged-activity-recent--container {
  padding: 20px;
  height: calc(50% - 40px);
  font-size: 14px;
  font-weight: 500;
  overflow: auto;
}

.workstreams-activity-preview-empty--container:last-child,
.workstreams-tagged-activity-recent--container:last-child {
  border-top: 1px solid var(--color-neutral-7);
}

.workstreams-activity-preview-empty--text {
  margin-top: 40%;
  color: var(--color-neutral-9);
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
}

.workstreams-activity-preview-empty--icon {
  padding-bottom: 8px;
}

.workstream-pins--loading {
  font-size: 16px;
  color: var(--color-neutral-10);
  display: flex;
  align-items: baseline;
  margin: 40px auto;
}

.workstream-pins-sections--container {
  width: 80%;
  max-width: 800px;
  padding: 20px 32px;
  overflow: auto;
}

.workstream-pins-section-content--container {
  display: flex;
  flex-wrap: wrap;
}

.public-pin--container.workstreams-activity--pin {
  width: initial;
}

.workstreams-show-more--link:hover {
  color: var(--color-purple-7);
}

.workstreams-show-more--link {
  color: var(--color-purple-5);
  font-size: 12px;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-top: 16px;
  display: inline-block;
}

.workstream-pins-section--header:hover {
  color: var(--color-neutral-12);
}

.workstream-pins-section--header {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-neutral-11);
  font-size: 12px;
  width: 100%;
  cursor: pointer;
  transition: color 0.3s ease;
}

.workstream-pins-section--container:last-child {
  border-top: 1px solid var(--color-neutral-7);
  margin-top: 20px;
}

.workstream-pins-section-header--icon {
  margin-right: 4px;
}

button.workstream-pins-section--pin-btn-hidden {
  display: none;
}

.workstream-tagged-activity-recent--loading {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  color: var(--color-neutral-9);
  margin: 24px;
  justify-content: center;
}

.workstream-notes--container {
  padding: 24px 0 0 32px;
  max-width: 800px;
}

.workstream-notes-note-edit--container {
  margin: 24px 8px 24px 32px;
  height: calc(100vh - 168px);
}

.workstreams-tagged-activity--title {
  margin: 0 0 20px;
}

.workstream-tagged-activity-notes-viewing--container {
  padding: 8px 0 8px 8px;
  height: 100%;
  box-sizing: border-box;
}