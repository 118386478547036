/* tagged activity -> calendar event */

.tagged-document-activity-event--container {
  background-color: var(--color-neutral-4);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 5px;
}

.tagged-document-activity-event--icon {
  color: var(--color-neutral-8);
  padding: 4px 16px 4px 4px;
  min-width: 20px;
  max-width: 20px;
  margin-left: 4px;
}

.tagged-document-activity-event--title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-neutral-12);
}

.tagged-document-activity-event--date-time {
  display: flex;
  font-size: 10px;
  font-weight: 400;
}

.tagged-document-activity-event--date {
  color: var(--color-neutral-11);
}

.tagged-document-activity-event--time {
  padding-left: 16px;
  color: var(--color-neutral-9);
}

/* tagged activity -> document */

.tagged-document-activity--container {
  border: solid 1px var(--color-neutral-7);
  margin-top: 12px;
  padding: 4px 4px;
  border-radius: 5px;
}

.tagged-document-activity-content-container {
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagged-document-activity--content {
  font-size: 14px;
  font-weight: 800;
  color: var(--color-neutral-12);
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 1;
  min-width: 0;
}

.tagged-document-activity--icon {
  margin-right: 16px;
  color: var(--color-neutral-9);
  font-size: 16px;
  text-align: center;
}

.tagged-document-activity--time {
  font-size: 12px;
  color: var(--color-neutral-9);
  white-space: nowrap;
}

.tagged-document-activity--loading {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-neutral-10);
  display: flex;
  align-items: baseline;
  margin: 0 8px;
  padding: 11px 12px;
  border: 1px solid var(--color-neutral-8);
  border-radius: 5px;
}

.tagged-document-activity--container.activity {
  padding: 0;
  border: 0;
}

.tagged-document-activity--container.activity .message-context-content--container {
  margin: 0;
}

.tagged-document-activity--container.activity .message-context-message--header {
  padding: 15px 12px;
}

.tagged-note-workstream--text {
  display: inline-block;
  margin-left: 4px;
}