.link-cards-inner--container:hover {
  border-color: var(--color-purple-5);
}

.link-cards-inner--container.selected {
  border-color: var(--color-purple-5);
}

.link-cards-inner--container.clickable {
  cursor: pointer;
}

.link-cards-inner--container {
  display: inline-flex;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--color-neutral-7);
  font-size: 12px;
  transition: border-color 0.3s ease;
  width: calc(100% - 30px);
}

.link-card-data--content {
  margin-left: 8px;
  flex-grow: 1;
  overflow-x: hidden;
}
.link-card-data--reason {
  color: var(--color-neutral-10);
  font-size: 10px;
}

.link-card-data--score {
  font-size: 8px;
  color: var(--color-neutral-9);
  float: right;
}

.link-cards--container {
  display: flex;
  flex-wrap: wrap;
}

.link-card-data--name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

.link-card--container.unread .link-card-data--name {
  font-weight: 700;
}
.link-card--container.unread .link-cards-inner--container {
  background: var(--color-neutral-2);
}
.link-card--container.unread .link-card--date,
.link-card--container.unread {
  font-weight: 600;
  color: var(--color-neutral-12);
}

.link-card--container {
  margin-bottom: 8px;
  margin-right: 8px;
  margin-top: 12px;
  position: relative;
  min-width: calc(50% - 20px);
}

.link-card--file-container {
  width: calc(100% - 30px);
}

.link-card--container.hover .link-card--pin:hover,
.link-card--container:hover .link-card--pin:hover {
  box-shadow: var(--box-shadow-2);
}

.link-card--container.hover .link-card--pin,
.link-card--container:hover .link-card--pin {
  opacity: 1;
  z-index: 1;
  box-shadow: var(--box-shadow-1);
}

.link-card--actions {
  position: absolute;
  top: -12px;
  right: 0;
  z-index: 1;
  display: flex;
}

.link-card--connect-actions {
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 1;
  font-size: 12px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.link-card--connect-text {
  height: 16px;
}

.link-card--connect-button {
  height: 16px;
  padding: 3px;
}

.link-card--connect-info {
  padding: 0 4px;
  height: 16px;
  width: 10px;
  min-width: 10px;
}

.link-card--info-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 300px;
  font-size: 12px;
  margin: 16px;
  gap: 8px;
  text-align: center;
}

.link-card--pin.pinning {
  cursor: not-allowed;
  color: var(--color-neutral-10);
  background: var(--color-neutral-5);
}

.link-card--link {
  color: var(--color-purple-5);
  background: white;
  border-radius: 25px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
}

.link-card--container.hover .link-card--link:hover,
.link-card--container:hover .link-card--link:hover {
  box-shadow: var(--box-shadow-2);
}

.link-card--container.hover .link-card--actions,
.link-card--container:hover .link-card--actions {
  z-index: 1;
}

.link-card--container.hover button.link-card-pin-space--button,
.link-card--container:hover button.link-card-pin-space--button {
  opacity: 1;
  box-shadow: var(--box-shadow-1);
  transition: all 0.3s ease;
}

.link-card--container.hover .link-card--link,
.link-card--container:hover .link-card--link {
  opacity: 1;
  box-shadow: var(--box-shadow-1);
}

/* pinning unpinning icon */

.link-card--pin.with-space {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--color-neutral-9);
  padding-right: 10px;
  background: white;
  color: var(--color-neutral-7);
}

.link-card--pin.with-space.unpin {
  color: var(--color-purple-5);
}

.link-card--pin {
  color: white;
  background: var(--color-neutral-8);
  border-radius: 25px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
  cursor: pointer;
}

.link-card--pin.unpin {
  color: var(--color-purple-5);
  background: white;
}

/* pinning unpinning icon --HOVER-- */

.link-card--pin.with-space.unpin:hover,
.link-card--pin.unpin:hover {
  color: var(--color-purple-3);
};

.link-card--pin.with-space:hover,
.link-card--pin:hover {
  color: var(--color-purple-4);
}

.link-card--pin,
.link-card--link {
  width: 26px;
  height: 26px;
  box-sizing: border-box;
}

.link-card--date {
  font-size: 10px;
  color: var(--color-neutral-10);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* pin hover */

.link-card--link-icon {
  background-color: var(--color-neutral-8);
  padding: 4px;
  border-radius: 2px;
}

.link-card--link-image {
  height: 20px;
  width: 20px;
  object-fit: cover;
}


.url-card--container:hover {
  background: var(--color-neutral-3);
}

.url-card--container {
  padding: 8px 16px 8px 8px;
  background: white;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid var(--color-neutral-8);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-width: 240px;
  margin-right: 12px;
}

.url-card--icon {
  margin-right: 8px;
  color: var(--color-blue-2-5);
}

.link-card-pill--link-image {
  width: 15px;
  height: 18px;
  object-fit: cover;
}

.link-card-pill-data--name {
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-cards-pill-inner--container {
  display: flex;
  align-items: center;
  max-width: 174px;
}

.link-card-pill--container:hover {
  background-color: var(--color-neutral-7);
}

.link-card-pill--container {
  border: 1px solid var(--color-neutral-8);
  border-radius: 5px;
  display: inline-flex;
  max-width: 200px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
  margin: 0 8px;
  height: 40px;
  box-sizing: border-box;
}

button.link-card-pin-space--button {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent !important;
  height: 26px;
  padding: 4px 4px 4px 10px;
  font-size: 10px;
  color: var(--color-neutral-12);
  opacity: 0;
  margin-left: 4px;
}

.link-card-actions-pin--container {
  display: flex;
}


.link-card-unread--indicator {
  width: 4px;
  height: 4px;
  position: absolute;
  background: var(--color-red-3);
  border-radius: 25px;
  top: 4px;
  right: 16px;
}