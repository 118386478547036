.connect-google-account-card {
  width: 444px;
  text-align: center;
}

.connect-google-account--error {
  margin-bottom: 24px;
}

.connect-google-account--description {
  color: var(--color-neutral-11);
  line-height: 24px;
  margin: 24px 0;
}

.connect-google-account--google-icons  {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-google-account--gmail-icon {
  height: 44px;
}

.connect-google-account--gcal-icon {
  margin: 0 40px;
}

.connect-google-account--gdrive-icon {
  height: 35px;
}
