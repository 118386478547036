.search-results--count {
  font-size: 14px;
}

.search-results--container {
  margin: 0 96px;
}

.link-row--container:hover {
  box-shadow: var(--alt-box-shadow-2);
}

.link-row--container.selected:hover,
.link-row--container.selected {
  box-shadow: none;
  border-color: #7649fd;
}

.link-row--container {
  display: flex;
  padding: 14px;
  align-items: center;
  border: 2px solid transparent;
  box-shadow: var(--alt-box-shadow-1);
  border-radius: 5px;
  justify-content: flex-start;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  margin-top: 16px;
  position: relative;
}

.link-row--icon {
  height: 49px !important;
  width: 34px !important;
}

.link-row--inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 24px);
}

.link-row--name {
  font-size: 16px;
  margin-left: 30px;
  max-width: 85%;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-row--description {
  font-size: 12px;
  font-style: italic;
  max-width: 85%;
  margin-left: 30px;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-row--details {
  font-size: 10px;
  color: var(--color-neutral-10);
  margin-left: 30px;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note-row--name {
  font-size: 12px;
  margin-left: 30px;
  max-width: 85%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note-row--date {
  color: var(--color-neutral-9);
  margin-right: 10px;
}

.note-row--title {
  font-weight: bold;
  margin-right: 10px;
}

.note-row--calendar-icon {
  margin-top: 2px;
}

.note-row--icon {
  border-radius: 6px;
  color: var(--color-purple-6);
  background: var(--color-purple-1);
}

.note-row--description {
  font-size: 12px;
  font-style: italic;
  max-width: 85%;
  margin-left: 13px;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}