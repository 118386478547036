.pw-reset-email-sent--card {
  width: 440px;
}

.pw-reset-email-sent--card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-neutral-11);
}

.pw-reset-email-sent--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px;
}

.pw-reset-email-sent--description {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.pw-reset-email-sent--subtext {
  margin-top: 16px;
}

.pw-reset-email-sent--submit-button {
  height: 40px;
  margin-top: 16px;
}

.pw-reset-login-link {
  margin-top: 12px;
}

.onboarding-alert-children {
  font-size: 14px;
  color: #696f8c;
  margin-top: 8px;
  line-height: 1;
  letter-spacing: -0.05px;
}
