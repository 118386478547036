.onboarding-card-with-header--background {
  background-color: var(--color-neutral-3);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
}

.onboarding-card-with-header--container {
  background-color: var(--color-neutral-1);
  border: 1px solid var(--color-neutral-8);
  border-radius: 5px;
  padding: 32px;
  width: 312px;
  margin: 104px auto;
  box-shadow: var(--box-shadow-1);
}

.onboarding-card-with-header--header {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-neutral-11);
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboarding-card-with-header--classify-logo {
  margin-bottom: 8px;
}
