.document-timeline--header {
  font-weight: 500;
  margin: 32px 0;
}

.document-timeline--container {
  margin: 24px 96px;
}

.document-group--container:first-child .document-group--header {
  margin-top: 16px;
}

.document-group--header {
  font-size: 18px;
  margin-top: 32px;
}

.document-group--container {
  position: relative;
}

.document-row--icon.under-icon--right {
  left: 4px;
  z-index: 0;
}

.document-row--icon {
  width: 15px;
  height: 15px;
  display: block;
  border: 1px solid var(--color-neutral-6);
  border-radius: 20px;
  padding: 4px;
  background: white;
  position: absolute;
  bottom: -8px;
  background: white;
  left: -11px;
  z-index: 1;
}

.document-row--container:hover {
  box-shadow: var(--alt-box-shadow-2);
}

.document-row--container.selected:hover,
.document-row--container.selected {
  box-shadow: none;
  border-color: #7649fd;
}

.document-row--container {
  display: flex;
  padding: 14px;
  align-items: center;
  height: 40px;
  border: 2px solid transparent;
  box-shadow: var(--alt-box-shadow-1);
  border-radius: 5px;
  justify-content: space-between;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  margin-top: 16px;
  position: relative;
}

.document-row--inner-container {
  display: flex;
  align-items: center;
  max-width: calc(100% - 164px);
}

.document-row--source {
  font-size: 11px;
  margin-top: 4px;
}

.document-row--filename {
  font-size: 16px;
  margin-left: 30px;
  max-width: 85%;
}

.document-row-filename--content {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.document-row--actions {
  font-size: 12px;
  color: var(--color-blue-4);
  display: flex;
  align-items: center;
  min-width: 112px;
  position: absolute;
  right: 0;
}

.document-row--time {
  position: absolute;
  top: 26px;
  left: -72px;
  font-size: 10px;
  color: var(--color-neutral-8);
  display: inline-block;
  max-width: 72px;
}

.document-row--send-context {
  font-size: 11px;
  margin-top: 4px;
  color: var(--color-blue-3);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 20vw;
}

.sender-context--link {
  color: blue;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.3s ease;
  text-decoration: none;
}

.sender-context--link:hover {
  color: darkblue;
}

.document-row--loading {
  margin-right: 8px;
}

.document-timeline--loading {
  font-weight: 600;
  color: var(--color-neutral-9);
  font-size: 12px;
  position: relative;
  bottom: -35px;
  display: inline-block;
}

.document-timeline--loading-content {
  display: flex;
  align-items: baseline;
}

.document-timeline--section {
  padding-bottom: 80px;
}

.document-row-icons--container {
  position: relative;
  z-index: 0;
}

.document-row--button {
  margin-right: 8px;
}

.activity-document--row {
  position: relative;
}