.signup-survey-select {
  display: block;
  width: 100%;
  margin: 4px 0 32px 0;
}

.signup-survey-submit-button {
  height: 40px;
  transition: all .3s ease;
}

.signup-survey-footer {
  display: flex;
  width: 100%;
  justify-content: right;
}
