.login-input {
  margin-bottom: 14px;
  margin-top: 2px;
}

.login-input.login-input--invalid {
  margin-bottom: 2px;
}

input.login-input:focus { 
  outline: none;
  border-color: var(--color-purple-5);
  box-shadow: none;
}

.login-input--label {
  font-size: 18px;
  font-weight: 400;
}

.login-input--error {
  color: var(--color-red-3);
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 8px;
}

.login-submit-button {
  width: 100%;
  margin: 10px 0 16px 0;
  height: 40px;
  transition: all .3s ease;
}

.login-link {
  text-decoration: none;
  color: var(--color-purple-5);
  transition: all 0.3s ease;
}

.login-link:hover {
  color: var(--color-purple-7);
}

.login-card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.forgot-password-link {
  display: block;
  margin-top: 12px;
}

.login-alert {
  margin-bottom: 24px;
}
