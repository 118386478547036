

.link-sidebar-title--container {
  display: flex;
  justify-content: space-between;
}

.view-link-sidebar--description.empty,
.view-link-sidebar--name.empty {
  font-style: italic;
  font-weight: 400;
  color: var(--color-neutral-9);
  font-size: 14px;
}

.view-link-sidebar--name {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-neutral-12);
  margin-bottom: 8px;
}

.view-link-sidebar--description {
  font-size: 16px;
  color: var(--color-neutral-12);
  margin-bottom: 16px;
}

.view-link-sidebar-name--input {
  margin-bottom: 8px;
}

.link-sidebar-content--link-icon {
  background-color: var(--color-neutral-8);
  padding: 4px;
  border-radius: 4px;
}

.link-sidebar-content--link-image {
  max-height: 56px;
  max-width: 56px;
}

.document-sidebar-basic--info.constrained-width {
  white-space: nowrap;
  max-width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
}