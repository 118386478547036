
.rule--container {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-7);
  border-radius: 4px;
  padding: 8px 24px;
  align-items: center;
  margin-bottom: 16px;
}


.rule-action--button {
  margin-left: 8px;
}

.condition-field--conjunction {
  font-size: 10px;
  color: var(--color-neutral-10);
  margin-top: 8px;
}

.condition-fields--field {
  width: 28%;
  margin-right: 8px;
}

.condition-fields--container {
  font-size: 12px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.new-rule-form--field-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.new-rule-form--field-description {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.new-rule-form--select-trigger {
  flex-grow: 1;
  margin-left: 8px;
}

.new-rule-form--field-container {
  margin-bottom: 16px;
  position: relative;
}

.new-rule-form--add-condition:hover {
  background: var(--color-neutral-4);
  color: var(--color-neutral-11);
}

.new-rule-form--add-condition {
  padding: 8px 24px;
  text-decoration: underline;
  cursor: pointer;
  background: var(--color-neutral-3);
  color: var(--color-neutral-10);
  font-size: 12px;
  transition: all 0.5s ease;
  border-radius: 4px;
  margin-top: 8px;
}

.new-rule-form--field-error > svg {
  margin-right: 8px;
}

.new-rule-form--field-error {
  color: var(--color-red-3);
  font-size: 12px;
  display: flex;
}

.new-rule-conditions-eval--container {
  font-size: 10px;
  position: absolute;
  top: 8px;
  right: 0;
}

.new-rule-conditions-eval--option {
  display: flex;
  align-items: center;
  color: var(--color-neutral-10);
  justify-content: space-evenly;
  margin-top: 4px;
}

.new-rule-form--select {
  font-size: 12px;
}