.timeline-top-bar--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeline-top-bar-inner--container {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.onboarding-section--container {
  margin-top: 10vh;
  padding-left: 10vw;
  width: 50vw;
}

.classify-btn.onboarding-section--btn {
  margin-top: 12px;
  display: inline-block;
  padding: 0px 60px 0 0;
  width: 240px;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto,arial,sans-serif;
  border-radius: 0;
  position: relative;
  height: 50px;
  margin-left: 16px;
  text-align: right;
}

.classify-btn.onboarding-section--btn.salesforce {
  padding: 0px 35px 0 0;
  font-family: Roboto,arial,sans-serif;
  background-color: white;
  color: black;
  border-color: #00a1e0;
}

.classify-btn.onboarding-section--btn.atlassian {
  padding: 0px 70px 0 0;
  border-color: #2684FF;
  background-color: white;
  color: black;
}

.classify-btn.onboarding-section--btn.microsoft {
  padding: 0px 50px 0 0;
  border-color: #03a9f4;
  background-color: white;
  color: black;
}

.classify-btn.onboarding-section--btn.trello {
  border-color: #03a9f4;
  background-color: white;
  color: black;
}

.classify-btn.onboarding-section--btn.trello > svg {
  top: 0;
}

.onboarding-section--hello {
  color: #acacac;
  font-weight: 500;
}


.onboarding-section--headline {
  font-size: 28px;
  font-weight: 500;
  margin: 8px 0;
}

.onboarding-section--description {
  color: #acacac;
  font-size: 14px;
  line-height: 18px;
}

.onboarding-section--icon {
  width: 22px;
  left: 16px;
  position: absolute;
  top: 13px;
}

.timeline--loading {
  width: 200px;
  display: flex;
  align-items: baseline;
  margin: 120px auto;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: var(--color-blue-1);
  justify-content: center;
}

.timeline-content--container {
  width: calc(70vw - 245px);
  flex-grow: 1;
  max-width: 75vw;
  min-height: 100vh;
}

.timeline--container {
  display: flex;
  overflow-x: hidden;
}