.calendar-time-slot--wrapper {
  font-size: 12px;
  color: var(--color-neutral-9);
  padding-left: 4px;
  margin-right: 7px;
  text-align: right;
}

.calendar-content--container {
  display: flex;
  overflow: hidden;
}

.calendar-content--container .rbc-today,
.calendar-content--container .rbc-time-header.rbc-overflowing {
  background: white;
}

.calendar-content--container .rbc-current-time-indicator {
  background-color: var(--color-red-3);
  height: 2px;
}

.calendar-content--container .rbc-timeslot-group {
  min-height: 60px;
}

.calendar-content--container .rbc-row.rbc-time-header-cell .rbc-header {
  font-size: 12px;
}

.calendar-content--container .rbc-day-slot .rbc-event.rbc-selected,
.calendar-content--container .rbc-event.rbc-selected {
  outline: none;
}

.calendar-content--container .rbc-day-slot .rbc-event,
.calendar-content--container .rbc-day-slot .rbc-background-event,
.calendar-content--container .rbc-event {
  font-size: 14px;
  outline: none;
}

.calendar-day--title {
  font-weight: 500;
  padding: 16px;
  color: var(--color-neutral-11);
  display: flex;
  align-items: baseline;
}

.calendar-day-toolbar--container {
  border-right: 1px solid var(--color-neutral-7);
  border-top: 1px solid var(--color-neutral-7);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-day-toolbar--navigate:hover {
  color: var(--color-neutral-12);
}

.calendar-day-toolbar--navigate:first-of-type {
  margin-left: 16px;
}

.calendar-day-toolbar--navigate {
  padding: 8px;
  cursor: pointer;
  color: var(--color-neutral-10);
  transition: color 0.3s ease;
}

.calendar-day-toolbar--actions {
  display: flex;
  align-items: center;
}

.calendar-event-block--notification {
  height: 8px;
  width: 8px;
  border-radius: 25px;
  background-color: var(--color-red-3);
  border: 1px solid white;
  display: inline-block;
  margin: 1px 4px;
}