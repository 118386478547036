
:root {
  --color-blue-1: #8b9ea1;
  --color-blue-2: #5c777c;
  --color-blue-3: #2e5057;
  --color-blue-4: #002a32;
  --color-blue-5: #002329;
  --color-blue-6: #001b20;
  --color-blue-7: #001417;
  --color-blue-8: #000c0e;

  --color-orange-1: #fee9db;
  --color-orange-2: #fddbc4;
  --color-orange-3: #fdccac;
  --color-orange-4: #fcbe95;
  --color-orange-5: #fcb07e;
  --color-orange-6: #cf9168;
  --color-orange-7: #a17151;
  --color-orange-8: #73503a;

  --color-yellow-1: #FFFAF1;
  --color-yellow-2: #FFEFD2;
  --color-yellow-3: #FFB020;
  --color-yellow-4: #996A13;
  --color-yellow-5: #66460D;

  --color-green-1: #dbf1eb;
  --color-green-2: #c3e9dd;
  --color-green-3: #ace0d0;
  --color-green-4: #94d7c3;
  --color-green-5: #7dcfb6;
  --color-green-6: #67aa95;
  --color-green-7: #508474;
  --color-green-8: #395f53;

  --color-pink-1: #f7d4e3;
  --color-pink-2: #f3b8d0;
  --color-pink-3: #ee9bbe;
  --color-pink-4: #e97fab;
  --color-pink-5: #e56399;
  --color-pink-6: #bc527e;
  --color-pink-7: #924062;
  --color-pink-8: #692d46;

  --color-red-1: #FDF4F4;
  --color-red-2: #F9DADA;
  --color-red-3: #D14343;
  --color-red-4: #A73636;
  --color-red-5: #7D2828;

  --color-purple-1: #F6F3FF;
  --color-purple-2: #F1ECFF;
  --color-purple-3: #D0C0FF;
  --color-purple-4: #A78AFC;
  --color-purple-5: #7649FD;
  --color-purple-6: #4F21D7;
  --color-purple-7: #330EA1;

  /* TODO: replace old green/blue with new ones */
  --color-green-2-1: #F5FBF8;
  --color-green-2-2: #DCF2EA;
  --color-green-2-3: #52BD94;
  --color-green-2-4: #429777;
  --color-green-2-5: #317159;

  --color-blue-2-1: #F3F6FF;
  --color-blue-2-2: #D6E0FF;
  --color-blue-2-3: #9DB5FF;
  --color-blue-2-4: #3366FF;
  --color-blue-2-5: #2952CC;
  --color-blue-2-6: #1F3D99;

  --color-neutral-1: #FFFFFF;
  --color-neutral-2: #FAFBFF;
  --color-neutral-3: #F9FAFC;
  --color-neutral-4: #F4F6FA;
  --color-neutral-5: #EDEFF5;
  --color-neutral-6: #E6E8F0;
  --color-neutral-7: #D8DAE5;
  --color-neutral-8: #C1C4D6;
  --color-neutral-9: #8F95B2;
  --color-neutral-10: #696F8C;
  --color-neutral-11: #474D66;
  --color-neutral-12: #101840;


  --box-shadow-1: 0 1px 2px 0 rgba(70, 82, 89, 0.24), 0 1px 3px 0 rgba(70, 82, 89, 0.12);
  --box-shadow-2: 0 2px 4px 0 rgba(70, 82, 89, 0.12), 0 3px 6px 0 rgba(70, 82, 89, 0.15);
  --box-shadow-3: 0 3px 6px 0 rgba(70, 82, 89, 0.1), 0 10px 20px 0 rgba(70, 82, 89, 0.15);
  --box-shadow-4: 0 5px 10px 0 rgba(70, 82, 89, 0.05), 0 15px 25px 0 rgba(70, 82, 89, 0.15);

  --alt-box-shadow-1: 0px 1px 4px 0px rgba(0,0,0,0.25);
  --alt-box-shadow-2: 0px 2px 8px 0px rgba(0,0,0,0.12);
}


.app-bootstrap--loading {
  width: 200px;
  display: flex;
  align-items: baseline;
  margin: 120px auto;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: var(--color-blue-1);
  justify-content: center;
}