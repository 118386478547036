.entity-tab--container {
  display: flex;
  border-bottom: 1px solid var(--color-neutral-7);
  margin-bottom: 8px;
  padding: 16px 16px 0;
}

.entity-tab--option.active {
  font-weight: 600;
  color: var(--color-purple-5);
}

.entity-tab--option {
  margin-right: 24px;
  color: var(--color-neutral-10);
  margin-bottom: 4px;
  font-size: 12px;
  cursor: pointer;
  min-width: 48px;
  text-align: center;
  padding-top: 8px;
}

.entity-tab--underline {
  border-top: 2px solid transparent;
  position: relative;
  bottom: -4px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: border-top-color 0.3s ease;
  margin-top: 4px;
}

.entity-tab--option:hover > .entity-tab--underline {
  border-top-color: var(--color-neutral-7);
}

.entity-tab--option.active > .entity-tab--underline {
  border-top-color: var(--color-purple-5);
}