.connect-success--container {
  margin-top: 25vh;
  padding-left: 10vw;
  width: 50vw;
}

.classify-btn.connect-success--btn {
  margin-top: 12px;
  width: 140px;
  display: flex;
  justify-content: space-evenly;
}

.connect-success--headline {
  font-size: 28px;
  font-weight: 500;
  margin: 8px 0;
}

.connect-success--description {
  color: #acacac;
  font-size: 14px;
  line-height: 18px;
}

.connect-success--icon {
  width: 18px;
}

.connect-success--loading {
  margin-top: 16px;
  display: flex;
  align-items: baseline;
  color: var(--color-blue-2);
}

.connect-success--failed {
  color: var(--color-pink-6);
  margin: 16px 0;
}

.connect-success--success {
  color: var(--color-green-6);
  margin-top: 16px;
  font-weight: 600;
}
