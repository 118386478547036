.view-document-sidebar--container {
  position: relative;
  right: 0;
  width: 30vw;
  min-width: 30vw;
  box-shadow: -1px 1px 2px 0px rgba(48, 60, 90, 0.42);
  bottom: 0;
  top: 0;
  transition: transform 0.3s ease;
  overflow-y: hidden;
  background: white;
}


.view-document-sidebar--container.hidden {
  transform: translateX(40vw);
  z-index: -1;
  position: fixed;
}

.view-document-sidebar--header {
  background: white;
  padding: 23px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DCD8E5;
  color: var(--color-blue-3);
  font-size: 18px;
  font-weight: 600;
  height: 26px;
}

.view-document-sidebar--subtitle {
  opacity: 0.6;
  font-size: 12px;
}

.view-document-sidebar--title {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25vw;
}

.view-document-sidebar--body {
  overflow: auto;
  height: calc(100vh - 76px);
  background: white;
}

.view-document-sidebar--row {
  display: flex;
  margin-bottom: 24px;
}

.view-document-sidebar--close:hover {
  color: var(--color-neutral-11);
}

.view-document-sidebar--close {
  font-size: 24px;
  cursor: pointer;
  color: var(--color-neutral-9);
  font-weight: 600;
  padding: 0px 6px 3px;
  transition: color 0.3s ease;
  position: absolute;
  top: 24px;
  right: 16px;
  height: 20px;
  display: flex;
  align-items: center;
}

.document-sidebar-basic--info.title {
  font-weight: 600;
  color: var(--color-blue-3);
}

.document-sidebar-basic--info {
  font-size: 14px;
  color: var(--color-blue-2);
}

.document-sidebar-basic--container {
  padding: 24px;
  width: 100%;
}

.document-sidebar-basic-info--container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DCD8E5;
}

.classify-btn.document-sidebar-action--button {
  border-radius: 8px;
  padding: 15px 15px;
  margin-bottom: 4px;
  width: 60px;
}

.document-sidebar-action--container {
  text-align: center;
  font-size: 12px;
}

.document-sidebar-actions--container {
  padding: 7.5px 32px;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #DCD8E5;
}

.document-sidebar-action--loading {
  display: flex;
  align-items: baseline;
}


.search-relevance--pill.very-high {
  background: var(--color-orange-5);
}

.search-relevance--pill.high {
  background: var(--color-green-6);
}

.search-relevance--pill.medium {
  background: var(--color-blue-2);
}

.search-relevance--pill.low {
  background: var(--color-pink-5);
}

.search-relevance--pill {
  display: inline-flex;
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 8px;
  color: white;
  font-weight: 700;
  border-radius: 12px;
}

.search-score--container {
  background: #f4f4f4;
  padding: 16px;
  border-bottom: 1px solid var(--color-blue-1);
}

.search-score--title {
  margin: 0 0 8px;
  font-weight: 500;
  font-size: 18px;
}

.search-score--section {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.search-score-relevance-field--container {
  display: inline-flex;
  font-size: 14px;
  background: white;
  border-radius: 5px;
  border: 1px solid var(--color-blue-1);
  align-items: center;
  margin: 8px 8px 8px 0;
}

.search-score-relevance-field--elt {
  padding: 4px 8px;
}

.search-score-relevance-field--elt:last-child {
  border-left: 1px solid var(--color-blue-1);
}

.view-document-sidebar--header.loading-skeleton {
  display: flex;
  flex-direction: column;
}

.view-doc-loading--wrapper {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  margin-top: 80px;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.view-doc-loading--image.small {
  height: 40px;
  width: 40px;
}

.view-doc-loading--image {
  height: 80px;
  width: 80px;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  position: relative;
  opacity: 0.6;
  border-radius: 3px;
}

.view-doc-loading--text {
  margin-left: 20px
}

.view-doc-loading--text-line {
  height: 14px;
  width: 140px;
  margin: 4px 0;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  position: relative;
  opacity: 0.6;
  border-radius: 3px;
}

.view-document-sidebar--body.loading-skeleton {
  padding: 24px 40px;
}

.view-document-sidebar--body .view-doc-loading--image,
.view-document-sidebar--body .view-doc-loading--text-line {
  opacity: 1;
}

.view-document-sidebar-content--container {
  position: fixed;
  top: 0;
  right: 0;
  width: inherit;
}

.document-sidebar-basic--info.subtitle {
  margin-top: 16px;
  font-weight: 700;
}

.document-sidebar-basic-info--icon {
  margin-left: 16px;
  position: relative;
}

.document-sidebar-basic-info-provider--icon {
  display: block;
  border: 1px solid var(--color-neutral-6);
  border-radius: 20px;
  padding: 5px;
  background: white;
  position: absolute;
  bottom: -15px;
  left: -10px;
  width: 24px;
  height: 24px;
  z-index: 1;
}

.document-sidebar-basic-info-provider--icon.under-icon--right {
  left: 8px;
  z-index: 0;
}

.view-document-sidebar--title svg:hover {
  background: var(--color-neutral-4);
  color: var(--color-neutral-11);
}

.view-document-sidebar--title svg {
  margin-right: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  color: var(--color-neutral-9);
  transition: all 0.3s ease;
}

.view-document-sidebar--title {
  display: flex;
  align-items: center;
}