.classify-sidebar--container {
  width: 230px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.classify-sidebar-link:hover {
  background: white;
}

.classify-sidebar-link {
  color: var(--color-neutral-11);
  padding: 4px;
  margin: 8px 16px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 14px;
  align-items: center;
}

.sidebar-sibling-content--container {
  margin-left: 230px;
}

.classify-sidebar-links--container {
  margin-top: 16px;
}

.classify-sidebar-link.blue.active,
.classify-sidebar-link.blue:hover {
  background: var(--color-blue-2-2);
}

.classify-sidebar-link.blue .classify-sidebar-link--icon {
  background: var(--color-blue-2-3);
}

.classify-sidebar-link.green.active,
.classify-sidebar-link.green:hover {
  background: var(--color-green-2-2);
}

.classify-sidebar-link.green .classify-sidebar-link--icon {
  background: var(--color-green-2-3);
}

.classify-sidebar-link.red.active,
.classify-sidebar-link.red:hover {
  background: var(--color-red-2);
}

.classify-sidebar-link.red .classify-sidebar-link--icon {
  background: var(--color-red-3);
}

.classify-sidebar-link.yellow.active,
.classify-sidebar-link.yellow:hover {
  background: var(--color-yellow-2);
}

.classify-sidebar-link.yellow .classify-sidebar-link--icon {
  background: var(--color-yellow-3);
}

.classify-sidebar-link.purple.active,
.classify-sidebar-link.purple:hover {
  background: var(--color-purple-2);
}

.classify-sidebar-link.purple .classify-sidebar-link--icon {
  background: var(--color-purple-5);
}

.classify-sidebar-link.active,
.classify-sidebar-link:hover {
  background: var(--color-neutral-5);
}

.classify-sidebar-link--icon {
  margin-right: 8px;
  padding: 7px;
  background: var(--color-neutral-11);
  border-radius: 5px;
  color: white;
  min-width: 16px;
}

.classify-sidebar-footer--container {
  font-size: 12px;
  position: absolute;
  bottom: 16px;
  left: 8px;
  color: var(--color-neutral-9);
}

.classify-sidebar-footer--feedback:hover {
  color: var(--color-blue-2-6);
}

.classify-sidebar-footer--feedback {
  text-decoration: underline;
  margin-bottom: 4px;
  cursor: pointer;
  transition: color 0.3s ease;
  color: var(--color-blue-2-4);
}


.classify-sidebar--version {
  color: var(--color-neutral-8);
}

.classify-sidebar--avatar {
  background: var(--color-neutral-9);
  color: var(--color-neutral-3);
  border-radius: 20px;
  width: 40px;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.classify-sidebar-header--container {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--color-neutral-8);
  justify-content: space-between;
}

.classify-sidebar-user-info--container {
  display: flex;
  align-items: center;
}

.classify-sidebar-user-info--name {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
}

.classify-sidebar-open--icon:hover {
  color: var(--color-neutral-11);
}

.classify-sidebar-open--icon {
  color: var(--color-neutral-9);
  cursor: pointer;
  padding: 4px;
  transition: color 0.3s ease;
}

.classify-sidebar-workstreams--container {
  font-size: 14px;
  margin: 8px 0 8px 16px;
  color: var(--color-neutral-11);
  font-weight: 500;
  border-top: 1px solid var(--color-neutral-7);
  padding-top: 8px;
}

.classify-sidebar-workstreams--header {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.classify-sidebar-workstreams--link.small {
  padding: 2px 8px;
}

.classify-sidebar-workstreams--link:last-child {
  margin-bottom: 0;
}

.classify-sidebar-workstreams--link {
  text-decoration: none;
  margin-bottom: 8px;
  border-radius: 5px;
  padding: 4px 12px;
  background: white;
  border: 1px solid black;
  display: block;
  width: max-content;
  font-weight: 600;
  max-width: 172px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.classify-sidebar-workstreams-header--icon {
  cursor: pointer;
  margin-right: 8px;
  color: var(--color-neutral-9);
}

.classify-sidebar-workstreams--empty {
  font-size: 12px;
  color: var(--color-neutral-9);
}