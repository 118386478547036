
.document-card-file--container.clickable:hover {
  border-color: var(--color-purple-5);
}

.document-card-file--container.selected {
  border-color: var(--color-purple-5);
}

.document-card-file--container.clickable {
  cursor: pointer;
}

.document-card--container.unread .document-card-file--name {
  font-weight: 700;
}
.document-card--container.unread .document-card--date,
.document-card--container.unread {
  font-weight: 600;
  color: var(--color-neutral-12);
}

.document-card--container.unread .document-card-file--container {
  background: var(--color-neutral-2);
}

.document-card-file--content {
  margin-left: 8px;
  flex-grow: 1;
  overflow-x: hidden;
}

.document-card-file--reason {
  color: var(--color-neutral-10);
  font-size: 10px;
}

.document-card-file--score {
  font-size: 8px;
  color: var(--color-neutral-9);
  float: right;
}

.document-card-file--name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

.document-card--container {
  margin-right: 8px;
  margin-top: 12px;
  position: relative;
  min-width: calc(50% - 20px);
  flex-grow: 1;
}

.document-card-file--container {
  display: inline-flex;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--color-neutral-7);
  font-size: 12px;
  transition: border-color 0.3s ease;
  background: white;
  width: calc(100% - 30px);
}

.document-card--container.hover .document-card--pin:hover,
.document-card--container:hover .document-card--pin:hover {
  box-shadow: var(--box-shadow-2);
}

.document-card--container.hover .document-card--pin,
.document-card--container:hover .document-card--pin {
  opacity: 1;
  z-index: 1;
  box-shadow: var(--box-shadow-1);
}

.document-card--container.hover .document-card--link:hover,
.document-card--container:hover .document-card--link:hover {
  box-shadow: var(--box-shadow-2);
}

.document-card--container.hover .document-card--link,
.document-card--container:hover .document-card--link {
  opacity: 1;
  box-shadow: var(--box-shadow-1);
}

.document-card--container.hover button.document-card-pin-space--button,
.document-card--container:hover button.document-card-pin-space--button {
  opacity: 1;
  box-shadow: var(--box-shadow-1);
}

/* pinning unpinning icon */

.document-card--pin.with-space {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--color-neutral-9);
  padding-right: 10px;
  background: white;
  color: var(--color-neutral-7);
}

.document-card--pin.with-space.unpin {
  color: var(--color-purple-5);
}

.document-card--pin {
  color: white;
  background: var(--color-neutral-8);
  border-radius: 25px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
  cursor: pointer;
}

.document-card--pin.unpin {
  color: var(--color-purple-5);
  background: white;
}

/* pinning unpinning icon --HOVER-- */

.document-card--pin.with-space.unpin:hover,
.document-card--pin.unpin:hover {
  color: var(--color-purple-3);
}

.document-card--pin.with-space:hover,
.document-card--pin:hover {
  color: var(--color-purple-4);
}

.document-card-sender-ctx--container {
  margin-top: 4px;
  color: var(--color-neutral-10);
}

.document-card--pin,
.document-card--link {
  width: 26px;
  height: 26px;
  box-sizing: border-box;
}

.document-card--date {
  font-size: 10px;
  color: var(--color-neutral-10);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
}

.document-card--link {
  color: var(--color-purple-5);
  background: white;
  border-radius: 25px;
  padding: 5px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
  cursor: pointer;
  margin-right: 4px;
}

.document-card-pill-file--name {
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-card-pill-file--container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.document-card-pill--container:not(.disabled):hover {
  background-color: var(--color-neutral-7);
}

.document-card-pill--container:not(.disabled) {
  cursor: pointer;
}

.document-card-pill--container {
  border: 1px solid var(--color-neutral-8);
  border-radius: 5px;
  display: inline-flex;
  max-width: 200px;
  padding: 8px 12px;
  background-color: white;
  transition: background-color 0.3s ease;
  margin: 0 8px;
  height: 40px;
  box-sizing: border-box;
  min-width: 0;
  flex-shrink: 1;
}

.document-card-pill--icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.document-card-file--icon {
  flex-shrink: 0;
}

.document-card-tags--tag {
  width: 8px;
  height: 4px;
  border-radius: 25px;
  margin-right: 4px;
}

.document-card-tags--container {
  position: absolute;
  bottom: 0;
  right: 8px;
  display: flex;
  padding: 4px;
}

.document-card-tags-popover--container {
  font-size: 10px;
  padding: 12px;
  width: fit-content;
}

.document-card-tags-popover--title {
  margin-bottom: 8px;
  color: var(--color-neutral-12);
}

button.document-card-pin-space--button {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* not a huge fan or evergreen for giving me no good way to do this */
  box-shadow: var(--box-shadow-1) !important;
  border: 0px solid transparent !important;
  height: 26px;
  padding: 4px 4px 4px 10px;
  font-size: 10px;
  color: var(--color-neutral-12);
  opacity: 0;
  transition: all 0.3s ease;
}

.document-card-actions-pin--container {
  display: flex;
}


.document-card--actions {
  position: absolute;
  top: -12px;
  right: 0;
  z-index: 1;
  display: flex;
}


.document-card--pin.pinning {
  cursor: not-allowed;
  color: var(--color-neutral-10);
  background: var(--color-neutral-5);
}


.document-card-actions-pin-space--button:not(.disabled):hover {
  background: var(--color-purple-1);
}

.document-card-actions-pin-space--button.selected,
.document-card-actions-pin-space--button.disabled {
  cursor: unset;
}

.document-card-actions-pin-space--button.selected,
.document-card-actions-pin-space--button.selected.disabled {
  font-weight: 600;
  color: var(--color-neutral-12);
}

.document-card-actions-pin-space--button.disabled {
  color: var(--color-neutral-7);
}

.document-card-actions-pin-space--button {
  color: var(--color-neutral-10);
  font-size: 10px;
  padding: 8px 12px;
  cursor: pointer;
}

.document-card-unread--indicator {
  width: 4px;
  height: 4px;
  position: absolute;
  background: var(--color-red-3);
  border-radius: 25px;
  top: 4px;
  right: 16px;
}

.document-card-date--author {
  display: flex;
  align-items: baseline;
  margin-left: 4px;
}