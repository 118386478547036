.signup-complete--card {
  width: 420px;
  color: var(--color-neutral-11);
  text-align: center;
}

.signup-complete--header {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-top: 16px;
}

.signup-complete--description {
  font-size: 14px;
  line-height: 24px;
}

.signup-complete--submit-button {
  height: 40px;
  margin-top: 32px;
}
