.calendar-event-details--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-event-details--title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-neutral-11);
}

.calendar-event-details--container {
  border-top: 1px solid var(--color-neutral-7);
  border-right: 1px solid var(--color-neutral-7);
  flex-grow: 1;
  max-width: 65%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  transition: max-width 0.3s ease;
  display: flex;
  flex-direction: column;
}

.calendar-event-details--container.small {
  max-width: 45%;
}

.calendar-event-details--section:first-child {
  padding: 24px 16px 16px 16px;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 1;
}

.calendar-event-details--section.notes {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.calendar-event-details--section {
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid var(--color-neutral-6);
}

.calendar-event-details--duration {
  font-size: 10px;
  color: var(--color-neutral-10);
}

.calendar-event-details--recurrence {
  color: var(--color-neutral-9);
  margin-left: 4px;
}

.calendar-event-details--reltime {
  color: var(--color-neutral-9);
  margin-left: 4px;
}

.calendar-detail-section--header.collapsible:hover {
  color: var(--color-neutral-11);
}

.calendar-detail-section--header.collapsible {
  cursor: pointer;
  transition: color 0.2s ease;
  margin-bottom: 0;
}

.calendar-detail-section--header {
  color: var(--color-neutral-10);
  margin-bottom: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}

.calendar-detail-section--title {
  display: inline-block;
  margin-left: 8px;
  font-weight: 500;
}

.calendar-detail-guests--guest:hover {
  background: var(--color-neutral-4);
}

.calendar-detail-guests--guest {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid var(--color-neutral-7);
  margin-right: 8px;
  display: inline-block;
  background: white;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 8px;
}

.calendar-detail-guests--container {
  color: var(--color-neutral-10);
  font-size: 12px;
  font-weight: 500;
}

.calendar-detail-documents--empty.collapsible {
  margin: 8px 0;
  display: inline-block;
  color: var(--color-neutral-10);
  font-size: 12px;
}

.calendar-detail-documents--empty {
  font-size: 12px;
  color: var(--color-neutral-10);
  margin: 0 0 8px;
}

.calendar-detail-description--container > p {
  margin: 8px 0;
}

.calendar-detail-description--container {
  color: var(--color-neutral-10);
  font-size: 12px;
}

.calendar-detail-guests--guest.selected {
  background: var(--color-neutral-7)
}

.calendar-detail-section--cta:hover {
  color: var(--color-neutral-10);
}

.calendar-detail-section--cta {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-neutral-9);
  transition: color 0.3s ease;
}

.calendar-detail-section--caret.closed {
  transform: rotate(90deg);
}

.calendar-detail-section--caret {
  margin-left: 8px;
  transition: transform 0.2s ease;
}

.calendar-pins--empty {
  font-size: 12px;
  color: var(--color-neutral-10);
  margin: 8px 0 8px;
}

.calendar-event-details-header--actions {
  display: flex;
  align-items: center;
}

.calendar-view-select--icon {
  position: absolute;
  left: 12px;
  top: 8px;
}

.calendar-view-select--option:not(:last-child) {
  border-bottom: 1px solid var(--color-neutral-7);
}

.calendar-view-select--option.selected {
  background: var(--color-neutral-4);
}

.calendar-view-select--option:not(.selected):hover {
  background: var(--color-neutral-3);
  cursor: pointer;
}

.calendar-view-select--option {
  font-weight: 500;
  padding: 4px 4px 4px 32px;
  position: relative;
  transition: background 0.3s ease;
}

.calendar-view-select--container {
  width: 200px;
}

.calendar-context-section--container {
  margin: 0 16px;
}

.calendar-context-section--header {
  color: var(--color-neutral-11);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  margin-top: 16px;
}

.calendar-context-section--header.collapsible {
  cursor: pointer;
}

.calendar-context-section--header.collapsible:hover {
  color: var(--color-neutral-12);
}

.calendar-context-section--collapsible {
  cursor: pointer;
}

.calendar-context-section--collapsible-caret.closed {
  transform: rotate(90deg);
}

.calendar-context-section--collapsible-caret {
  margin-left: 8px;
  transition: transform 0.2s ease;
}

.calendar-context-pinned-content--container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.calendar-context-related-content--container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.calendar-context-activity-since-last-time--line {
  border-bottom: 1px solid var(--color-red-3);
  width: calc(100% - 185px);
  left: -24px;
  position: relative;
  font-size: 10px;
  padding-left: 16px;
  top: 3px;
}

.calendar-context-activity-since-last-time--container {
  color: var(--color-red-3);
  position: relative;
  height: 8px;
}

.calendar-context-activity-since-last-time--text {
  position: absolute;
  right: 0;
  top: -4px;
  font-size: 12px;
}

.calendar-event-details-activity--tabname {
  position: relative;
}

.calendar-event-details-activity--dot {
  width: 4px;
  height: 4px;
  background: var(--color-red-3);
  position: absolute;
  top: 6px;
  right: -8px;
  border-radius: 50%;
}

.calendar-context-activity--loading {
  font-size: 12px;
  color: var(--color-neutral-10);
  display: flex;
  align-items: baseline;
}

/* calendar context activity event */

.calendar-context-activity-event--container {
  background-color: var(--color-neutral-4);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 5px;
}

.calendar-context-activity-event--icon {
  color: var(--color-neutral-8);
  padding: 4px 16px 4px 4px;
}

.calendar-context-activity-event--date-time {
  display: flex;
  font-size: 10px;
  font-weight: 400;
}

.calendar-context-activity-event--date {
  color: var(--color-neutral-11);
}

.calendar-context-activity-event--time {
  padding-left: 16px;
  color: var(--color-neutral-9);
}

.calendar-context-activity-event--title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-neutral-12);
}

.calendar-detail-documents--loading {
  font-size: 12px;
  color: var(--color-neutral-10);
  display: flex;
  align-items: baseline;
}

.calendar-context-activity-filters--container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-context-activity-filters--container > * {
  margin-right: 8px;
}

.calendar-context-activity-filters--clear {
  color: var(--color-blue-2-5);
}

.calendar-context-activity-filters--clear {
  font-size: 12px;
  cursor: pointer;
  color: var(--color-blue-2-4);
  transition: color 0.3s ease;
}

.calendar-context-filter-option--icon {
  width: 16px;
  margin-right: 4px;
}

.calendar-context-activity-filter--button.filtered {
  background: var(--color-purple-1);
}

.message-context-content--container.calendar-details-activity--container {
  margin: 8px 0;
}

.link-card--container.calendar-context-link--container,
.document-card--container.calendar-context-document--container {
  width: calc(50% - 8px);
  flex-grow: unset;
}

.calendar-event-details-activity--tabicon {
  margin-right: 4px;
}

.calendar-notes-note-edit--datestr {
  font-weight: 600;
  white-space: nowrap;
}

.calendar-notes-note--title {
  margin: 0px 0px 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-neutral-12);
}

.calendar-notes-note--container:hover {
  background: var(--color-neutral-1);
}

.calendar-notes-note--container.template {
  background: var(--color-neutral-1);
  border-color: var(--color-purple-3);
}

.calendar-notes-note--container.disabled {
  border-color: var(--color-neutral-4);
  background: var(--color-neutral-4);
}

.calendar-notes-note--container.with-footer {
  padding: 16px 0 0 0;
}

.calendar-notes-note--content-preview.with-footer,
.calendar-notes-note-title--container.with-footer {
  padding-left: 16px;
  padding-right: 16px;
}

.calendar-notes-note--container {
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--color-neutral-6);
  margin-right: 16px;
  cursor: pointer;
  background: var(--color-neutral-2);
  transition: background 0.3s ease;
  margin-bottom: 16px;
  position: relative;
}

.calendar-notes-note-edit--header > .calendar-notes-editor--title:focus,
.calendar-context-section--notes-editor > .calendar-context-section-notes--textarea:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.calendar-context-section--notes-editor {
  height: calc(100% - 76px);
}

.calendar-context-section-notes--textarea {
  resize: none;
  height: calc(100% - 32px);
  overflow-y: auto;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  color: var(--color-neutral-11);
  padding: 4px 16px;
}

.calendar-event-details-notes--loading {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  color: var(--color-neutral-10);
}

.template .calendar-notes-note--content-preview {
  font-style: italic;
}

.calendar-notes-note--content-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: var(--color-neutral-10);
}

.calendar-notes-note-preview--footer {
  font-size: 10px;
  color: var(--color-neutral-10);
  border-top: 1px solid var(--color-neutral-7);
  padding: 8px 16px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.calendar-notes-note-header--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-notes-note-edit--header {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding: 16px 16px 0 16px;
  height: 24px;
  min-width: 0px;
}

.calendar-notes-note-edit-back--button {
  margin-right: 8px;
  padding: 4px 8px;
}

.calendar-notes-editor--footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: var(--color-neutral-9);
  height: 16px;
  border-top: 1px solid var(--color-neutral-7);
  padding: 4px 16px;
  position: relative;
}

.calendar-notes-footer--saving {
  display: flex;
  align-items: center;
}

.calendar-notes-footer--saved {
  color: var(--color-green-5);
  animation: fadeAway 1s 0.2s forwards;
}

@keyframes fadeAway {
  from { opacity: 1; }
  to { opacity: 0; }
}

.calendar-notes-footer--failed {
  color: var(--color-red-3);
}

input.calendar-notes-editor--title {
  flex-grow: 1;
  border: none;
  font-size: 16px;
  padding: 0 0 0 4px;
  color: var(--color-neutral-12);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.calendar-notes-note-edit--container.error {
  border-color: var(--color-red-3);
}

.calendar-notes-note-edit--container.disabled {
  background-color: #F4F5F9;
}

.calendar-notes-note-edit--container {
  border: 1px solid var(--color-neutral-7);
  border-radius: 5px;
  margin-right: 16px;
  height: 100%;
}

.calendar-notes-note-preview--icon {
  color: var(--color-purple-5);
  margin-right: 8px;
}

.calendar-note-fake-note--loading {
  display: inline-flex;
  align-items: center;
}

.public-pin--container:hover > .calendar-pin-context--pinner {
  opacity: 1;
}

.calendar-pin-context--pinner {
  font-size: 9px;
  color: var(--color-neutral-9);
  position: relative;
  top: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.public-pin--container {
  width: 50%;
  flex-grow: unset;
}

.calendar-context-pinned--container {
  width: unset;
}

.calendar-context-section--header label {
  margin-left: 8px;
}

.calendar-notes-note-title--container {
  display: flex;
  justify-content: space-between;
}

.calendar-notes-note-header--container {
  margin-right: 16px;
}

.calendar-notes-note-right-content--container {
  display: flex;
  align-items: center;
}

.calendar-notes-note-title--shared {
  font-size: 10px;
  color: var(--color-neutral-10);
}

.note-actions--container {
  display: flex;
  justify-content: flex-end;
}

.calendar-note-actions-delete--button {
  margin-right: 16px;
  padding: 4px 8px;
}

.workstream-note-actions-delete--button {
  padding: 8px 8px;
  margin: 12px 0px 8px 12px;
}

.calendar-notes-note--container.disabled.unread,
.calendar-notes-note--container.disabled.unread .calendar-notes-note-title--shared,
.calendar-notes-note--container.disabled.unread .calendar-notes-note--content-preview {
  font-weight: 600;
  color: var(--color-neutral-12);
}

.note-container-unread--indicator {
  width: 4px;
  height: 4px;
  position: absolute;
  background: var(--color-red-3);
  border-radius: 25px;
  top: 20px;
  right: 8px;
}


button.note-pin-space--button {
  border-radius: 15px;
  /* not a huge fan or evergreen for giving me no good way to do this */
  box-shadow: var(--box-shadow-1) !important;
  border: 0px solid transparent !important;
  height: 26px;
  padding: 4px 4px 4px 10px;
  font-size: 10px;
  color: var(--color-neutral-12);
  margin-right: 16px;
}

.note-actions-pin-space--button:not(.disabled):hover {
  background: var(--color-purple-1);
}

.note-actions-pin-space--button.selected,
.note-actions-pin-space--button.disabled {
  cursor: unset;
}

.note-actions-pin-space--button.selected,
.note-actions-pin-space--button.selected.disabled {
  font-weight: 600;
  color: var(--color-neutral-12);
}

.note-actions-pin-space--button.disabled {
  color: var(--color-neutral-7);
}

.note-actions-pin-space--button {
  color: var(--color-neutral-10);
  font-size: 10px;
  padding: 8px 12px;
  cursor: pointer;
}



.calendar-notes-note-locked--icon {
  padding: 19px;
  background: var(--color-red-2);
  border-radius: 50%;
  margin: 16px;
}

.calendar-notes-note-locked--container {
  display: flex;
  align-items: center;
  padding: 16px 24px;
}

.calendar-notes-note-locked--content {
  font-size: 14px;
}