.connector-info--container {
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  border-radius: 5px;
  position: relative;
  margin-top: 12px;
  justify-content: space-between;
  border: 1px solid var(--color-neutral-8)
}

.connector-info--icon {
  width: 24px;
  margin-right: 16px;
}

.classify-btn.disable-connector-modal--cancel-btn {
  font-weight: 600;
}

.classify-btn.disable-connector-modal--disable-btn:hover {
  background: var(--color-pink-6);
}

.classify-btn.disable-connector-modal--disable-btn {
  background: var(--color-pink-5);
  font-weight: 600;
  margin-right: 16px;
}

.disable-connector-modal--disable-btn.enable {
  background: var(--color-green-5);
}

.disable-connector-modal--disable-btn.enable:hover {
  background: var(--color-green-6);
}

.disable-connector-modal--footer {
  position: absolute;
  border-top: 1px solid var(--color-blue-1);
  width: calc(100% - 48px);
  padding: 24px;
  bottom: 0;
  text-align: right;
  background: white;
}

.disable-connector-modal--title {
  font-weight: 400;
  padding: 24px;
  font-size: 24px;
  margin: 0;
}

.disable-connector-modal--confirm {
  padding: 0 24px 24px;
  position: absolute;
  bottom: 10px;
  font-size: 14px;
  z-index: 1;
}

.disable-connector-modal--account {
  padding: 0 24px 24px;
  color: var(--color-blue-4);
}

.disable-connector-modal--description {
  padding: 24px;
  width: 80%;
  font-size: 14px;
  color: var(--color-blue-4);
}

.connector-info--disable {
  position: absolute;
  color: var(--color-pink-5);
  right: 0px;
  padding: 8px;
  cursor: pointer;
}

.connector-info--enable {
  position: absolute;
  color: var(--color-green-5);
  right: 0px;
  padding: 8px;
  cursor: pointer;
}

.connected-account-group--container {
  border: 1px solid var(--color-neutral-8);
  border-radius: 5px;
  padding: 8px 16px;
  margin-top: 12px;
}

.connected-account-group--icon {
  margin-right: 12px;
}

.connected-account-group--title {
  align-items: center;
  display: flex;
  position: relative;
}

.connectors-list--row {
  margin-bottom: 4px;
  font-size: 14px;
}

.connectors-list--container {
  margin-left: 56px;
}

.connectors-list--row > span {
  margin-left: 4px;
}

.connectors-list--row > a {
  margin-left: 4px;
  text-decoration: none;
}

.connectors-list--row {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: var(--color-neutral-10);
  font-weight: 500;
}

.connected-account-reconnect--button {
  margin-right: 8px;
}

.connected-account-actions--group {
  position: absolute;
  right: 0;
  top: 8px;
  display: flex;
}

.connected-account-actions-status--container {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--color-neutral-11);
}

.connector-info--title {
  display: flex;
  align-items: center;
}