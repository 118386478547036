.calendar-pin-select-file--container {
  padding: 0 12px;
}

.calendar-pin-select-file-search--container {
  position: relative;
}

.pin-file-search-results--header {
  font-size: 12px;
  color: var(--color-neutral-9);
  margin-top: 12px;
  margin-bottom: 8px;
}

.pin-file-search-results--doc.pinned,
.pin-file-search-results--doc:hover {
  background: var(--color-neutral-4);
}

.pin-file-search-results--doc,hover .pin-file-search-results-doc--already-pinned,
.pin-file-search-results--doc:hover .pin-file-search-results-doc--already-pinned {
  opacity: 1;
}

.pin-file-search-results-doc--already-pinned {
  position: absolute;
  top: -12px;
  font-size: 10px;
  color: var(--color-neutral-12);
  left: 0;
  font-style: italic;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.pin-file-search-results--doc.hover .pin-file-search-results--mask,
.pin-file-search-results--doc:hover .pin-file-search-results--mask {
  opacity: 1;
}

.pin-file-search-results--mask-bg {
  background: var(--color-neutral-12);
  opacity: 0.3;
  height: 100%;
  border-radius: 3px;
}

.pin-file-search-results--mask {
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
}

.pin-file-search-results--doc {
  border: 1px solid var(--color-neutral-7);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: background 0.3s ease;
}

.pin-file-search-results--filename {
  font-size: 12px;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.pin-file-search-results--date {
  font-size: 10px;
  color: var(--color-neutral-9);
}

.pin-file-search-results--container {
  max-height: 220px;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.pin-file-search-results--doc .document-row--icon {
  width: 12px;
  left: -8px;
}

.pin-file-search-result--header {
  margin-left: 12px;
}

.calendar-pin-select-file-search--container > * {
  z-index: 0;
}

.pin-file-search-results--loading {
  margin: 56px auto;
  width: 40px;
}

.pin-file-search-results--doc.hover .pin-file-action--button.unpin,
.pin-file-search-results--doc:hover .pin-file-action--button.unpin {
  color: var(--color-neutral-7);
}

.pin-file-action--button.unpin {
  color: var(--color-purple-5);
}

.pin-file-search-results--doc.hover .pin-file-action--button.pin,
.pin-file-search-results--doc:hover .pin-file-action--button.pin {
  color: var(--color-purple-5);
}

.pin-file-search-results--doc.hover .document-card--pin,
.pin-file-search-results--doc:hover .document-card--pin,
.pin-file-search-results--doc.hover button.document-card-pin-space--button,
.pin-file-search-results--doc:hover button.document-card-pin-space--button {
  opacity: 1;
}

.pin-file-search-result-action--container {
  position: absolute;
  top: 17px;
  right: 9px;
  display: flex;
}

.pin-file-action--button {
  position: absolute;
  right: 8px;
  padding: 8px;
  color: var(--color-neutral-7);
  transition: color 0.3s ease;
  cursor: pointer;
}

.pin-file-search-results--empty > * {
  margin-top: 24px;
}

.pin-file-search-results--empty {
  text-align: center;
  color: var(--color-neutral-9);
  margin: 64px auto;
}

.calendar-pin-link--container label {
  font-size: 12px;
  font-weight: normal;
  color: var(--color-neutral-11);
}

.calendar-pin-link--container {
  margin: 4px 12px 12px;
  width: 280px;
  height: 288px;
}

.calendar-pin-link--title {
  font-weight: normal;
  font-size: 14px;
  color: var(--color-neutral-11);
}

.calendar-pin-link-buttons--container > button {
  margin-left: 8px;
}

.calendar-pin-link-buttons--container {
  position: absolute;
  bottom: 12px;
  right: 12px;
}