.settings-sidebar--container {
  background: #502cb8;
  color: white;
  height: calc(100vh - 32px);
  width: 170px;
  padding: 16px 12px;
  margin-right: 16px;
}

.settings-sidebar--link:hover {
  background: #5a37bf;
}

.settings-sidebar--link {
  color: white;
  text-decoration: none;
  display: block;
  padding: 6px 4px;
  border-radius: 3px;
  transition: background 0.2s ease;
}

.settings-sidebar--link.active {
  background: #5e36d0;
}

.settings-sidebar-links--container {
  margin-top: 12px;
}

.accounts-settings-connectors--container {
  margin-top: 16px;
  width: 33vw;
  min-width: 500px;
}

.accounts-settings--connector {
  margin-bottom: 24px;
}

.account-settings--container {
  margin-left: 40px;
}

.general-settings-form-input--container > label {
  display: none;
}

.general-settings-form-input--container > input {
  flex-grow: 1;
  background: transparent;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: transparent;
  display: block;
  font-size: 14px;
  border-color: transparent;
  margin-left: 8px;
}

.general-settings-form-input--container {
  display: flex;
  justify-content: center;
  padding: 12px;
  margin: 16px 40px;
  background: #e8eaea;
  align-items: center;
  color: var(--color-blue-2);
  border-radius: 3px;
}


.general-settings--form {
  width: 35vw;
}

.account-settings--title {
  font-weight: 500;
  margin-top: 40px;
}
.general-settings--title {
  margin: 40px;
  font-weight: 500;
}

.general-settings-submit--btn {
  width: 140px;
  margin-top: 24px;
  margin-left: 40px;
}

.general-settings--admin-container {
  font-size: 16px;
  margin-left: 40px;
  margin-top: 64px;
  font-weight: 500;
}

.rules-list--container {
  margin: 40px 24px;
  max-width: 700px;
}

.rules-list-title--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rules-list-title--button:last-child {
  margin-right: 0;
}

.rules-list-title--button {
  margin: 0 8px;
}
